import { FC } from 'react';

import { Button } from '@mantine/core';

import { BizPayPrimaryButtonProps } from './BizPayPrimaryButton.types';

const BizPayPrimaryButton: FC<BizPayPrimaryButtonProps> = ({ children, ...otherProps }) => {
  return (
    <Button
      size="md"
      sx={({ black, colors }) => ({
        backgroundColor: colors['bizpay-teal'][3],
        borderColor: colors['bizpay-teal'][4],
        boxShadow: `0.05rem 0.05rem ${colors['bizpay-light-gray'][2]}`,
        color: black,

        ':hover': {
          backgroundColor: colors['bizpay-teal'][4],
          borderColor: colors['bizpay-teal'][5],
        },
      })}
      type="button"
      {...otherProps}
    >
      {children}
    </Button>
  );
};

export { BizPayPrimaryButton };
